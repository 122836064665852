import "./paymentPage.scss";

const paymentPage = () => {
  return (
    <div className="PaymentPage">
      <h1>Payment Result</h1>
    </div>
  );
};

export default paymentPage;